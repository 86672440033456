




























































import axios from "axios";
import marked from "marked";
import Vue from "vue";
let rendererMD = new marked.Renderer();
marked.setOptions({
  renderer: rendererMD,
  gfm: true,
  breaks: false,
  pedantic: false,
  sanitize: false,
  smartLists: true,
  smartypants: false,
});
rendererMD.heading = function (text, level) {
  return `<h${level} style="margin-bottom:20px;margin-top:20px">${text}</h${level}>`;
};
rendererMD.link = function (href, title, text) {
  return `<a style="color:#4183c4;text-decoration: none;" href=${href}>${text}</a>`;
};
export default Vue.extend({
  data: () => ({
    serveraddr: Vue.prototype.serverAddr + "/",
    imagelist: [] as any,
    rules: [(value: any) => !!value || "Required"],
    name: "",
    content: "",
    processedContent: "",
    file: {} as any,
    type: "",
    group: "",
    types: ["news", "recommend", "notice"],
    picStart: "<img src=\"localhost/",
    picEnd: "\" width=100%/>"
  }),
  computed: {
    compiledMarkdown: function () {
      return marked(this.processedContent);
    },
  },
  mounted: function () {
    const id = this.$route.params.id;
    if (id != "!") {
      console.log("getting " + id);
      let intro = axios
        .post(Vue.prototype.serverAddr + "/api/get-item-by-id", {
          collection: "intro",
          _id: id,
        })
        .then((response) => {
          console.log(response);
          let data: [] = response.data as any;
          if (data.length > 0) {
            const item = (data as any)[0];
            this.name = item.name;
            this.content = item.content != undefined ? item.content : "";
            this.processedContent = this.content.replace(
              /localhost/g,
              Vue.prototype.serverAddr
            );
            this.imagelist = item.imagelist != undefined ? item.imagelist : [];
            this.group = item.group;
            this.type = item.type;
          }
        });
    }
  },
  watch: {},
  methods: {
    update: function (e: any) {
      this.content = e;
      this.processedContent = this.content.replace(
        /localhost/g,
        Vue.prototype.serverAddr
      );
    },
    submit: function () {
      let method = "";
      if (this.$route.params.id == "!") {
        method = "post";
      } else {
        method = "update";
      }
      console.log("post in intro")
      axios
        .post(Vue.prototype.serverAddr + "/api/" + method + "-item", {
          _id: this.$route.params.id,
          collection: "intro",
          content: this.content,
          imagelist: this.imagelist,
          type: this.type,
          group: this.group,
        })
        .then((response) => {
          this.$router.back();
        });
    },
    cancel: function () {
      this.$router.back();
    },
    getFile(file: any) {
      this.file = file;
    },
    uploadFile() {
      const URL = Vue.prototype.serverAddr + "/api/upload";

      let data = new FormData();
      data.append("name", "my-picture");
      data.append("file", this.file);

      let config = {
        header: {
          "Content-Type": "multipart/form-data",
        },
      };

      axios.post(URL, data, config as any).then((response) => {
        console.log("image upload response > ", response);
        if (response.status == 200) {
          this.imagelist.push(this.file.name);
        }
      });
    },
  },
});
